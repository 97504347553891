
import { defineStore } from 'pinia'
import type {
  CarritoItem,
DatosCliente,
FormaPago,
FormaEnvio,
Vars,
} from '../types/stores'
export const useShowOffCarro = defineStore(
  'offcanvas-carro',
  () => {

    const show =    ref<boolean>(false)
    const addedNew = ref<boolean>(false)

    return { show, addedNew };
  }, { persist: false },
)

export const useCarroCompras = defineStore(
  'carrocompras',
  () => {
    const carrito = ref<CarritoItem[]>([]);
    //variable para ux. No tienen valor por defecto porque solo se usan para mostrar ciertos items
    const vars = ref<Vars>({
      pasos_carro: 1,
      // radio_forma_pago: 1, 
      /**
       * @var radio_forma_pago_transferencia existira cuando radio_forma_pago == -10 || -20, es decir metodo transferencia bancaria o cripto
       */
      // radio_forma_pago_transferencia: 1, 
      //radio_forma_envio: 1, 
      //radio_tipo_sesion: 1,

    })
    const forma_pago = ref<FormaPago>({
      // codigo: -30, //valor requerido por el back para que identifique transferencia
      // codigo: -10, //valor requerido por el back para que identifique cripto
      //tipoPago: '', //hace referencia al codigo de la forma de pago en la tabla moneda
      // nombre: '',
      // comentario: '',
      // detalle_cuenta: '',//detalle de cuenta a transferir o cripto 
      // transferencia: false,
      // cripto:false,
    });
    const forma_envio = ref<FormaEnvio>({
      // codigo: '',
      // nombre: '',
      // comentario: '',
      // direccion:'',
      // provincia: '',
      // localidad: '',
      // codigo_postal: '',
      // costo_envio: 0,
    });
    const datos_cliente = ref<DatosCliente>({
      factura_a: false,
      // radio_tipo_sesion: null, esta variable no se usa mas. Se usa vars.radio_tipo_sesion
      // nombre: '',
      // apellido: '',
      // email: '',
      // telefono: '',
      // direccion: '',
      // cp: '',
      // localidad: '',
      // provincia: '',
      // observacion: '',
      //email: '',
      //password: '',
      //password2: '',

      //dni: '',
      //cuit: '',
      razon_social: {
        // razonsocial:"",
        // razonsocial_localidad:{
        // codigo: ,
        // nombre:,
        // },
        // condicion_fiscal:{} //Esto se encontra en datos_cliente
        // razonsocial_provincia:{
        // codigo: ,
        // nombre:,
        // },
        // razonsocial_direcion:"",
        // razonsocial_numero:""
        // razonsocial_piso:""
        // razonsocial_dpto:""
        // razonsocial_cp:'',
      },
    })
    /**
     * @param {codigo,cantidad, venta_maxima, stock:{stock:0}} item 
     * @param {int} cantidad 
     * @returns 
     */
    const addToCarro = (item:CarritoItem, cantidad?:number):void => {
      // Si el usuario vuelve a agregar o quitar productos al carrito o modificar cantidades se reiniciara el proceso
      vars.value.pasos_carro = 1

      if (!item) return;

      // se busca producto en carro
      const index = carrito.value.findIndex(i => i.codigo === item.codigo);
      // se agrega si no se encuentra
      if (index == -1) {
        carrito.value.push({
          codigo: item.codigo,
          nombre: item.nombre,
          cantidad: cantidad ?? 1,
          imagen: item.imagen,
          stock: item.stock,
          sugerencia: item?.sugerencia ?? false,
          sugerenciaSubcategoria: item?.sugerenciaSubcategoria,
          productoSugerencia: item?.productoSugerencia,

        });
      } else {
        carrito.value[index].stock = item.stock;

        // se aumenta la cantidad del producto o se suma la cantidad deseada        
        if (!cantidad) {

          //Si venta_maxima==0 significa que el unico limitante es el stock y se verifica eso

          if (carrito.value[index].stock.venta_maxima == 0 && carrito.value[index].cantidad + 1 <= carrito.value[index].stock.stock) {
            carrito.value[index].cantidad++;
            return
          } else if (carrito.value[index].stock.venta_maxima == 0 && carrito.value[index].cantidad + 1 > carrito.value[index].stock.stock) {
            carrito.value[index].cantidad = carrito.value[index].stock.stock
            return
          }

          if (carrito.value[index].cantidad + 1 <= carrito.value[index].stock.venta_maxima) {
            carrito.value[index].cantidad++;
            if (carrito.value[index].cantidad > carrito.value[index].stock.stock) {
              carrito.value[index].cantidad = carrito.value[index].stock.stock
            }
            return;
          }

          if (carrito.value[index].cantidad + 1 > carrito.value[index].stock.venta_maxima) {
            // Si el stock es mayor que la cantidad productos del que se desea agregar 
            // y la cantidad es menor que la venta_maxima
            carrito.value[index].cantidad = carrito.value[index].stock.venta_maxima;

            if (carrito.value[index].stock.venta_maxima > carrito.value[index].stock.stock) {
              carrito.value[index].cantidad = carrito.value[index].stock.stock
            }
            return;
          }
          if (carrito.value[index].cantidad + 1 >= carrito.value[index].stock.stock) {

            carrito.value[index].cantidad--;
          }
          carrito.value[index].cantidad++
        } else {
          // se suma la cantidad deseada
          // Si hay menos productos del que se desea agregar se setea la cantidad del stock

          if (carrito.value[index].stock.venta_maxima == 0 && cantidad <= carrito.value[index].stock.stock) {
            carrito.value[index].cantidad = cantidad ==0?1:cantidad;
            return
          }
          // Se verifica que la cantidad no supere el stock y la cantidada maxima
          if (cantidad <= carrito.value[index].stock.venta_maxima) {
            carrito.value[index].cantidad = cantidad ==0?1:cantidad;
            if (cantidad >= carrito.value[index].stock.stock) {
              carrito.value[index].cantidad = carrito.value[index].stock.stock
            }
            return
          }
          // por defecto se setea el stock maximo
          carrito.value[index].cantidad = carrito.value[index].stock.stock
        }
      }
    }
    /**
     * 
     * @param {producto:{codigo,cantidad, venta_maxima, stock:{stock:0}}} item 
     * @returns void
     */
    const removeFromCarro = (item :CarritoItem, borrar = false):void => {
      // Si el usuario vuelve a agregar o quitar productos al carrito o modificar cantidades se reiniciara el proceso
      vars.value.pasos_carro = 1
      
      if (!item?.codigo) return;

      //si se manda borrar=true se elimina el producto del listado

      if (borrar) {
        // se borra el producto y la sugerencia si es que existe
        carrito.value = carrito.value.filter(i => i.productoSugerencia != item.codigo);
        carrito.value = carrito.value.filter(i => i.codigo != item.codigo);
        
      } else {
        // se busca producto en carro
        const index = carrito.value.findIndex(i => i.codigo === item.codigo);
        // se elimina si se encuentra
        if (index != -1) {
          if (carrito.value[index].cantidad > 1) {
            carrito.value[index].cantidad--;
          }
        }
      }
      if(carrito.value.length == 0){
        vars.value.pasos_carro = 1
        forma_pago.value = {}
      }
    }

    const isInCarro = (productoCodigo:number):boolean => {
      return carrito.value.some(i => i.codigo == productoCodigo);
    }
    return {
      vars,
      carrito,
      datos_cliente,
      forma_pago,
      forma_envio,
      addToCarro,
      removeFromCarro,
      isInCarro,
    };

  }, { persist: true })

