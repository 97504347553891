import { default as _91componente_93Qxd6zoesFQMeta } from "/app/pages/arma-tu-pc/[componente].vue?macro=true";
import { default as index7kjHz3JolsMeta } from "/app/pages/arma-tu-pc/index.vue?macro=true";
import { default as boton_45arrepentimientoPodRN9pzMEMeta } from "/app/pages/boton-arrepentimiento.vue?macro=true";
import { default as carro_45comprasHF0X1JxU9UMeta } from "/app/pages/carro-compras.vue?macro=true";
import { default as _91id_93_45_91_91prodNombre_93_93FkP7hLiOoHMeta } from "/app/pages/catalogo/[id]-[[prodNombre]].vue?macro=true";
import { default as _91buscador_93JU6TT8ZsrTMeta } from "/app/pages/catalogo/buscador/[buscador].vue?macro=true";
import { default as _91id_93_45_91productoNombre_930L4kLjMCcyMeta } from "/app/pages/catalogo/caracteristicas/[id]-[productoNombre].vue?macro=true";
import { default as _91id_93_45_91_91nombre_93_93UpU3o7SxoeMeta } from "/app/pages/catalogo/categoria/[id]-[[nombre]].vue?macro=true";
import { default as categoriasJ2JDICav9CMeta } from "/app/pages/catalogo/categorias.vue?macro=true";
import { default as indexrWcm4q4wYcMeta } from "/app/pages/catalogo/index.vue?macro=true";
import { default as liquidacionsJRHIS8X3wMeta } from "/app/pages/catalogo/liquidacion.vue?macro=true";
import { default as _91id_93_45_91nombre_93L40q09GPqmMeta } from "/app/pages/catalogo/marca/[id]-[nombre].vue?macro=true";
import { default as ofertassoF51V5QEQMeta } from "/app/pages/catalogo/ofertas.vue?macro=true";
import { default as _91id_93_45_91productoNombre_93naaGIZV1GkMeta } from "/app/pages/catalogo/opiniones/[id]-[productoNombre].vue?macro=true";
import { default as preventaOJ6wSSudYgMeta } from "/app/pages/catalogo/preventa.vue?macro=true";
import { default as _91productos_9303YTdY8GcLMeta } from "/app/pages/catalogo/productos-seleccionados/[productos].vue?macro=true";
import { default as _91id_93_45_91_91nombre_93_934KtculfpmgMeta } from "/app/pages/catalogo/subcategoria/[id]-[[nombre]].vue?macro=true";
import { default as _91id_93_45_91nombre_93SNrnlCBTnyMeta } from "/app/pages/catalogo/tag/[id]-[nombre].vue?macro=true";
import { default as ultimos_45ingresosXWZF9Pw9toMeta } from "/app/pages/catalogo/ultimos-ingresos.vue?macro=true";
import { default as comprar_45ayudaKzFazyJetBMeta } from "/app/pages/centro-ayuda/comprar-ayuda.vue?macro=true";
import { default as index0Cw3XiBpyKMeta } from "/app/pages/centro-ayuda/index.vue?macro=true";
import { default as metodo_45enviot5s3jLqCWQMeta } from "/app/pages/centro-ayuda/metodo-envio.vue?macro=true";
import { default as metodo_45pagos3uWiqWBgLMeta } from "/app/pages/centro-ayuda/metodo-pago.vue?macro=true";
import { default as seguimiento_45compraGENwqDkuebMeta } from "/app/pages/centro-ayuda/seguimiento-compra.vue?macro=true";
import { default as contactol7MrcsCCEUMeta } from "/app/pages/contacto.vue?macro=true";
import { default as cuentajM6pQD6pU3Meta } from "/app/pages/cuenta.vue?macro=true";
import { default as datos_45clientedsftXfDoReMeta } from "/app/pages/datos-cliente.vue?macro=true";
import { default as detalle_45compramub3xThimxMeta } from "/app/pages/detalle-compra.vue?macro=true";
import { default as formas_45enviodSz6DLKmQkMeta } from "/app/pages/formas-envio.vue?macro=true";
import { default as formas_45pagoXK7wZ07YBCMeta } from "/app/pages/formas-pago.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as datos_45personalestI8gn1e85BMeta } from "/app/pages/mi-cuenta/datos/datos-personales.vue?macro=true";
import { default as direccion_45de_45envioHkOb9Ju799Meta } from "/app/pages/mi-cuenta/datos/direccion-de-envio.vue?macro=true";
import { default as facturacionMN1FDSUEKrMeta } from "/app/pages/mi-cuenta/datos/facturacion.vue?macro=true";
import { default as indexhNnmw6UJw5Meta } from "/app/pages/mi-cuenta/datos/index.vue?macro=true";
import { default as indexpCS3XkEnsdMeta } from "/app/pages/mi-cuenta/index.vue?macro=true";
import { default as movimientosyMVm4OwcqSMeta } from "/app/pages/mi-cuenta/movimientos.vue?macro=true";
import { default as pedidosi7eITcHYSMMeta } from "/app/pages/mi-cuenta/pedidos.vue?macro=true";
import { default as _91orden_93rFhJNziz4JMeta } from "/app/pages/orden-compra/[orden].vue?macro=true";
import { default as pruebay878ScCsIuMeta } from "/app/pages/prueba.vue?macro=true";
import { default as quienes_45somos4vvdbDgeBhMeta } from "/app/pages/quienes-somos.vue?macro=true";
import { default as recepcion_45pago_91_91query_93_93E4QXQqfufPMeta } from "/app/pages/recepcion-pago[[query]].vue?macro=true";
import { default as registroEKktDPTg4gMeta } from "/app/pages/registro.vue?macro=true";
import { default as _91token_939hpQmsAyKFMeta } from "/app/pages/restablecer-password/[token].vue?macro=true";
import { default as _91token_93dCev2pITueMeta } from "/app/pages/seguimiento-compra/[token].vue?macro=true";
import { default as indexm0NiQYKqYWMeta } from "/app/pages/seguimiento-compra/index.vue?macro=true";
import { default as terminos_45y_45condicioneszDPdxo4fysMeta } from "/app/pages/terminos-y-condiciones.vue?macro=true";
import { default as _91token_93ywgyL7Nh53Meta } from "/app/pages/verifica-email/[token].vue?macro=true";
import { default as _91_46_46_46slug_93w667NbFLNaMeta } from "/app/pages/web/[...slug].vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "arma-tu-pc-componente",
    path: "/arma-tu-pc/:componente()",
    component: () => import("/app/pages/arma-tu-pc/[componente].vue").then(m => m.default || m)
  },
  {
    name: "arma-tu-pc",
    path: "/arma-tu-pc",
    component: () => import("/app/pages/arma-tu-pc/index.vue").then(m => m.default || m)
  },
  {
    name: "boton-arrepentimiento",
    path: "/boton-arrepentimiento",
    component: () => import("/app/pages/boton-arrepentimiento.vue").then(m => m.default || m)
  },
  {
    name: "carro-compras",
    path: "/carro-compras",
    meta: carro_45comprasHF0X1JxU9UMeta || {},
    component: () => import("/app/pages/carro-compras.vue").then(m => m.default || m)
  },
  {
    name: "catalogo-id-prodNombre",
    path: "/catalogo/:id()-:prodNombre?",
    meta: _91id_93_45_91_91prodNombre_93_93FkP7hLiOoHMeta || {},
    component: () => import("/app/pages/catalogo/[id]-[[prodNombre]].vue").then(m => m.default || m)
  },
  {
    name: "catalogo-buscador-buscador",
    path: "/catalogo/buscador/:buscador()",
    component: () => import("/app/pages/catalogo/buscador/[buscador].vue").then(m => m.default || m)
  },
  {
    name: "catalogo-caracteristicas-id-productoNombre",
    path: "/catalogo/caracteristicas/:id()-:productoNombre()",
    meta: _91id_93_45_91productoNombre_930L4kLjMCcyMeta || {},
    component: () => import("/app/pages/catalogo/caracteristicas/[id]-[productoNombre].vue").then(m => m.default || m)
  },
  {
    name: "catalogo-categoria-id-nombre",
    path: "/catalogo/categoria/:id()-:nombre?",
    meta: _91id_93_45_91_91nombre_93_93UpU3o7SxoeMeta || {},
    component: () => import("/app/pages/catalogo/categoria/[id]-[[nombre]].vue").then(m => m.default || m)
  },
  {
    name: "catalogo-categorias",
    path: "/catalogo/categorias",
    component: () => import("/app/pages/catalogo/categorias.vue").then(m => m.default || m)
  },
  {
    name: "catalogo",
    path: "/catalogo",
    component: () => import("/app/pages/catalogo/index.vue").then(m => m.default || m)
  },
  {
    name: "catalogo-liquidacion",
    path: "/catalogo/liquidacion",
    component: () => import("/app/pages/catalogo/liquidacion.vue").then(m => m.default || m)
  },
  {
    name: "catalogo-marca-id-nombre",
    path: "/catalogo/marca/:id()-:nombre()",
    meta: _91id_93_45_91nombre_93L40q09GPqmMeta || {},
    component: () => import("/app/pages/catalogo/marca/[id]-[nombre].vue").then(m => m.default || m)
  },
  {
    name: "catalogo-ofertas",
    path: "/catalogo/ofertas",
    component: () => import("/app/pages/catalogo/ofertas.vue").then(m => m.default || m)
  },
  {
    name: "catalogo-opiniones-id-productoNombre",
    path: "/catalogo/opiniones/:id()-:productoNombre()",
    meta: _91id_93_45_91productoNombre_93naaGIZV1GkMeta || {},
    component: () => import("/app/pages/catalogo/opiniones/[id]-[productoNombre].vue").then(m => m.default || m)
  },
  {
    name: "catalogo-preventa",
    path: "/catalogo/preventa",
    component: () => import("/app/pages/catalogo/preventa.vue").then(m => m.default || m)
  },
  {
    name: "catalogo-productos-seleccionados-productos",
    path: "/catalogo/productos-seleccionados/:productos()",
    component: () => import("/app/pages/catalogo/productos-seleccionados/[productos].vue").then(m => m.default || m)
  },
  {
    name: "catalogo-subcategoria-id-nombre",
    path: "/catalogo/subcategoria/:id()-:nombre?",
    meta: _91id_93_45_91_91nombre_93_934KtculfpmgMeta || {},
    component: () => import("/app/pages/catalogo/subcategoria/[id]-[[nombre]].vue").then(m => m.default || m)
  },
  {
    name: "catalogo-tag-id-nombre",
    path: "/catalogo/tag/:id()-:nombre()",
    meta: _91id_93_45_91nombre_93SNrnlCBTnyMeta || {},
    component: () => import("/app/pages/catalogo/tag/[id]-[nombre].vue").then(m => m.default || m)
  },
  {
    name: "catalogo-ultimos-ingresos",
    path: "/catalogo/ultimos-ingresos",
    component: () => import("/app/pages/catalogo/ultimos-ingresos.vue").then(m => m.default || m)
  },
  {
    name: "centro-ayuda-comprar-ayuda",
    path: "/centro-ayuda/comprar-ayuda",
    component: () => import("/app/pages/centro-ayuda/comprar-ayuda.vue").then(m => m.default || m)
  },
  {
    name: "centro-ayuda",
    path: "/centro-ayuda",
    component: () => import("/app/pages/centro-ayuda/index.vue").then(m => m.default || m)
  },
  {
    name: "centro-ayuda-metodo-envio",
    path: "/centro-ayuda/metodo-envio",
    component: () => import("/app/pages/centro-ayuda/metodo-envio.vue").then(m => m.default || m)
  },
  {
    name: "centro-ayuda-metodo-pago",
    path: "/centro-ayuda/metodo-pago",
    component: () => import("/app/pages/centro-ayuda/metodo-pago.vue").then(m => m.default || m)
  },
  {
    name: "centro-ayuda-seguimiento-compra",
    path: "/centro-ayuda/seguimiento-compra",
    component: () => import("/app/pages/centro-ayuda/seguimiento-compra.vue").then(m => m.default || m)
  },
  {
    name: "contacto",
    path: "/contacto",
    component: () => import("/app/pages/contacto.vue").then(m => m.default || m)
  },
  {
    name: "cuenta",
    path: "/cuenta",
    component: () => import("/app/pages/cuenta.vue").then(m => m.default || m)
  },
  {
    name: "datos-cliente",
    path: "/datos-cliente",
    meta: datos_45clientedsftXfDoReMeta || {},
    component: () => import("/app/pages/datos-cliente.vue").then(m => m.default || m)
  },
  {
    name: "detalle-compra",
    path: "/detalle-compra",
    meta: detalle_45compramub3xThimxMeta || {},
    component: () => import("/app/pages/detalle-compra.vue").then(m => m.default || m)
  },
  {
    name: "formas-envio",
    path: "/formas-envio",
    component: () => import("/app/pages/formas-envio.vue").then(m => m.default || m)
  },
  {
    name: "formas-pago",
    path: "/formas-pago",
    meta: formas_45pagoXK7wZ07YBCMeta || {},
    component: () => import("/app/pages/formas-pago.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: loginhHM0vSTW5jMeta || {},
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "mi-cuenta-datos-datos-personales",
    path: "/mi-cuenta/datos/datos-personales",
    meta: datos_45personalestI8gn1e85BMeta || {},
    component: () => import("/app/pages/mi-cuenta/datos/datos-personales.vue").then(m => m.default || m)
  },
  {
    name: "mi-cuenta-datos-direccion-de-envio",
    path: "/mi-cuenta/datos/direccion-de-envio",
    meta: direccion_45de_45envioHkOb9Ju799Meta || {},
    component: () => import("/app/pages/mi-cuenta/datos/direccion-de-envio.vue").then(m => m.default || m)
  },
  {
    name: "mi-cuenta-datos-facturacion",
    path: "/mi-cuenta/datos/facturacion",
    meta: facturacionMN1FDSUEKrMeta || {},
    component: () => import("/app/pages/mi-cuenta/datos/facturacion.vue").then(m => m.default || m)
  },
  {
    name: "mi-cuenta-datos",
    path: "/mi-cuenta/datos",
    component: () => import("/app/pages/mi-cuenta/datos/index.vue").then(m => m.default || m)
  },
  {
    name: "mi-cuenta",
    path: "/mi-cuenta",
    component: () => import("/app/pages/mi-cuenta/index.vue").then(m => m.default || m)
  },
  {
    name: "mi-cuenta-movimientos",
    path: "/mi-cuenta/movimientos",
    meta: movimientosyMVm4OwcqSMeta || {},
    component: () => import("/app/pages/mi-cuenta/movimientos.vue").then(m => m.default || m)
  },
  {
    name: "mi-cuenta-pedidos",
    path: "/mi-cuenta/pedidos",
    meta: pedidosi7eITcHYSMMeta || {},
    component: () => import("/app/pages/mi-cuenta/pedidos.vue").then(m => m.default || m)
  },
  {
    name: "orden-compra-orden",
    path: "/orden-compra/:orden()",
    component: () => import("/app/pages/orden-compra/[orden].vue").then(m => m.default || m)
  },
  {
    name: "prueba",
    path: "/prueba",
    component: () => import("/app/pages/prueba.vue").then(m => m.default || m)
  },
  {
    name: "quienes-somos",
    path: "/quienes-somos",
    component: () => import("/app/pages/quienes-somos.vue").then(m => m.default || m)
  },
  {
    name: "recepcion-pagoquery",
    path: "/recepcion-pago:query?",
    component: () => import("/app/pages/recepcion-pago[[query]].vue").then(m => m.default || m)
  },
  {
    name: "registro",
    path: "/registro",
    meta: registroEKktDPTg4gMeta || {},
    component: () => import("/app/pages/registro.vue").then(m => m.default || m)
  },
  {
    name: "restablecer-password-token",
    path: "/restablecer-password/:token()",
    component: () => import("/app/pages/restablecer-password/[token].vue").then(m => m.default || m)
  },
  {
    name: "seguimiento-compra-token",
    path: "/seguimiento-compra/:token()",
    component: () => import("/app/pages/seguimiento-compra/[token].vue").then(m => m.default || m)
  },
  {
    name: "seguimiento-compra",
    path: "/seguimiento-compra",
    component: () => import("/app/pages/seguimiento-compra/index.vue").then(m => m.default || m)
  },
  {
    name: "terminos-y-condiciones",
    path: "/terminos-y-condiciones",
    component: () => import("/app/pages/terminos-y-condiciones.vue").then(m => m.default || m)
  },
  {
    name: "verifica-email-token",
    path: "/verifica-email/:token()",
    component: () => import("/app/pages/verifica-email/[token].vue").then(m => m.default || m)
  },
  {
    name: "web-slug",
    path: "/web/:slug(.*)*",
    component: () => import("/app/pages/web/[...slug].vue").then(m => m.default || m)
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/web/inicio",
    component: component_45stubnO9MU04yTU
  }
]