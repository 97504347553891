<script setup>
import { useCarroCompras } from '@/stores/carrocompras';

const config = useRuntimeConfig()

useSeoMeta({
  title: `${config.public.razon_social} | Venta de productos de tecnologia`,
})
if(process.env.NODE_ENV === 'production') {
    useHead({
        htmlAttrs: {
            lang: "es-AR",
        },
    
        script: [{
        children: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${config.public.gtmContainerId}');`,
        tagPriority: 'high',
        tagPosition: 'head',
        async: true,
        defer: true,
        }],
        noscript: [{
        children: `<iframe src="https://www.googletagmanager.com/ns.html?id=${config.public.gtmContainerId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        tagPosition: 'bodyOpen'
        }]
        
    })

}

const useCarro = useCarroCompras();

onErrorCaptured(error => {
  console.log({ errorAppVue: error });
});

const syncStore = event => {
  if (event.key === 'carrocompras') {
    const updatedState = JSON.parse(event.newValue);
    Object.keys(updatedState).forEach(id => {
      if (useCarro[id]) {
        useCarro[id] = updatedState[id];
      }
    });
  }
};

onMounted(() => {
  window.addEventListener('storage', syncStore, { passive: true });
});

onBeforeUnmount(() => {
  window.removeEventListener('storage', syncStore);
});

const handleError = async(error) => {
  if (process.client) {
    if (window.history.state.back) {
      await navigateTo(window.history.state.back)
    } else {
      await navigateTo('/')
    }
  }
  error.value = null
}
</script>

<template>
	<NuxtLayout>
		<NuxtLoadingIndicator color="#348feb" :height="5" />
		<NuxtErrorBoundary>
			<NuxtPage />

			<template #error="{ error }">
				<div class="container-web">
					<h1 class="text-center">Algo salió mal</h1>
					<DevOnly>
						<pre class="text-center mt-10">{{ error }}</pre>
					</DevOnly>
					<div class="mt-16 pt-16 flex justify-center items-center">
						<button
							class="mt-4 mx-auto px-6 py-3 bg-light-primary text-[#fff] text-xl leading-tight uppercase rounded shadow-lg focus:shadow-outline focus:outline-none"
							@click="handleError(error)"
						>
							Recargar
						</button>
					</div>
				</div>
			</template>
		</NuxtErrorBoundary>
	</NuxtLayout>
</template>

<style>
html,
* {
	font-family: Roboto, sans-serif;
}

.hide-scroll::-webkit-scrollbar {
	display: none;
}

.hide-scroll {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.custom-loader {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background:
		radial-gradient(farthest-side, #766df4 94%, #0000) top/8px 8px no-repeat,
		conic-gradient(#0000 30%, #766df4);
	-webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
	mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
	animation: s3 1s infinite linear;
}

@keyframes s3 {
	100% {
		transform: rotate(1turn);
	}
}

.pointer {
	cursor: pointer;
}

.container-web {
	overflow-x: hidden;
	min-width: 300px;
	max-width: 100%;
	min-height: 100dvh;
	padding-bottom: 30px;
}

.carro-compras-title {
	user-select: none;
	margin: 18px auto 20px;
	text-align: center;
	font-size: 18px;
	font-weight: 400;
}

.sup-indice-precio {
	font-size: 8px;
	font-weight: 700;
}

@media (min-width: 768px) {
	.container-web {
		max-width: 1440px;
		margin: 0 auto;
		min-height: 100dvh;
	}
	.sup-indice-precio {
		font-size: 18px;
		font-weight: 600;
		text-decoration-line: underline;
	}
	.carro-compras-title {
		max-width: 1240px;
		margin: 20px auto;
		text-align: start;
		font-size: 30px;
		font-weight: 500;
	}
}

@media (min-width: 1024px) {
	.container-web {
		padding-top: 0;
		padding-inline: 5%;
	}
}

@media (min-width: 1440px) {
	.container-web {
		max-width: 1440px;
		min-height: 100dvh;
		padding-inline: 0;
	}
}

.unselectable {
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
</style>
