<template>
	<div class="my-5 text-center">
		<div v-if="error.statusCode === 404">
			<NuxtLink to="/">
				<img src="/images/logopng.webp" width="200px" />
			</NuxtLink>
			<div class="bg-light-primaryDark select-none my-10 py-5" style="color: #fff;">
				<h1>404</h1>
			</div>

			<template v-if="error.message.toLowerCase().includes('page not found')">
				<h3 class="select-none mt-10 font-semibold text-[18px]">
					¡Buscamos esta página por todos lados!
				</h3>
				<p class="select-none mt-4 font-medium text-[16px]">
					Pero quizás aún podamos ayudarte a encontrar lo que buscas
				</p>
				<p class="select-none mt-2 mb-5 font-medium text-[16px]">Prueba mejor suerte en estos links</p>
				<div class="list-items select-none mb-5">
					<NuxtLink class="mt-2 font-medium hover:text-[#fff]" to="/"> Inicio </NuxtLink>
					<NuxtLink class="mt-2 font-medium hover:text-[#fff]" to="/catalogo"> Productos </NuxtLink>
					<NuxtLink class="mt-2 font-medium hover:text-[#fff]" to="/contacto"> Contacto </NuxtLink>
					<NuxtLink class="mt-2 font-medium hover:text-[#fff]" to="/catalogo/categorias">
						Categorias
					</NuxtLink>
				</div>
			</template>
            <template v-else>
                <h3 class="select-none mt-10 font-semibold text-[18px]">
					{{ error.message }}
				</h3>
            </template>
		</div>
		<div v-else-if="error.statusCode === 500">
			<h2>Oh, oh, error del servidor (500)</h2>
			<p class="mt-2">
				Este es un problema en nuestro lado. Contacta con el servicio al
				cliente.
			</p>
			<br />
			<br />
			<br />
			<br />

			<dev-only>
        message:
        <pre>{{ props.error.message }}</pre>
			</dev-only>
			<br />
			<br />
			<br />
			<br />
			<!-- <dev-only> -->

			<div v-html="error.stack"></div>
			<!-- </dev-only> -->
			
		</div>
		<div v-else>
			<h1>Tenemos un error</h1>
			<p>Contáctanos para solucionarlo.</p>
		</div>
		<button class="bg-light-primary text-light-backgroundPage mt-4 px-4 py-2 rounded-lg shadow-lg font-semibold w-40 h-10" @click="handleError"> Volver </button>
	</div>
</template>
<style scoped>
h1 {
	font-size: 50px;
	font-weight: bold;
}
h2 {
	font-size: 30px;
}
.list-items a {
	display: block;
	width: 200px;
	margin: 0 auto;
	padding: 20px;
	border-left: 5px solid;
	position: relative;
	z-index: 2;
	text-decoration: none;
	/* color: #444; */
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

.list-items a:after {
	content: "";
	height: 100%;
	left: 0;
	top: 0;
	width: 0px;
	position: absolute;
	transition: all 0.3s ease 0s;
	-webkit-transition: all 0.3s ease 0s;
	z-index: -1;
}

.list-items a:hover:after {
	width: 100%;
}
.list-items a:first-child:after {
	background: #3498db;
}
.list-items a:nth-child(2):after {
	background: #ffd071;
}
.list-items a:nth-child(3):after {
	background: #f0776c;
}
.list-items a:last-child:after {
	background: #1abc9c;
}
@media (min-width: 1024px) {
	h1 {
		font-size: 80px;
	}
}
</style>

<script setup>
const props = defineProps({
	error: Object,
});

console.log(props.error);
const handleError = () => clearError({ redirect: "/" });
</script>
